import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import {
    getMaxLengthBasedOnDeviceWidth,
    ImagePreviewIconSvg,
    QuoteBeginIconSvg,
    TESTIMONIAL_DATA_TYPE,
    trimText,
} from "@Utils";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import VideoPlayer from "repoV2/components/common/VideoPlayer/VideoPlayer";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./testimonialCard.module.scss";
import commonStyles from "../../../root/yoga.module.scss";

export const TestimonialCard = ({
    data,
    onTestimonialClick,
}: ITemplate.ITestimonialCardProps) => {
    const TestimonialContent = () => {
        if (data?.type === TESTIMONIAL_DATA_TYPE.VIDEO) {
            return (
                <div
                    className={classNames(
                        styles.contentContainer,
                        styles.videoContainer
                    )}
                    onClick={onTestimonialClick}
                >
                    <VideoPlayer
                        videoUrl={data.media}
                        light
                        optimizeUsingThumbnail
                        width="100%"
                        height="100%"
                    />
                    <div className={styles.mediaOverlay} />
                </div>
            );
        }

        if (data?.type === TESTIMONIAL_DATA_TYPE.IMAGE) {
            return (
                <div
                    className={classNames(
                        styles.contentContainer,
                        styles.imageContainer,
                        commonStyles.borderRadius
                    )}
                    onClick={onTestimonialClick}
                >
                    <ExlyImage
                        fetchWidth={500}
                        alt="testimonial"
                        src={data.media}
                        className={styles.image}
                    />

                    <div className={styles.mediaOverlay}>
                        <ImagePreviewIconSvg height="40px" />
                    </div>
                </div>
            );
        }

        const maxLength = getMaxLengthBasedOnDeviceWidth([
            [769, 520],
            [250, 350],
        ]);

        const showReadMore: boolean = !!(data?.content?.length > maxLength);

        return (
            <div className={styles.contentContainer}>
                {trimText(data.content, maxLength)}
                {showReadMore ? (
                    <ButtonV2
                        design="plain"
                        applyTheme
                        className={styles.readMore}
                        onClick={onTestimonialClick}
                    >
                        Read More
                    </ButtonV2>
                ) : null}
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div
                className={classNames(
                    styles.container,
                    commonStyles.borderRadius
                )}
            >
                <div className={styles.quoteContainer}>
                    <QuoteBeginIconSvg className={styles.quote} />
                </div>
                <TestimonialContent />
                <hr />
                <div className={styles.authorContainer}>
                    <ExlyImage
                        fetchWidth={120}
                        alt={data.name}
                        src={data.cover_image}
                        width={60}
                        height={60}
                        className={commonStyles.borderRadius}
                    />
                    <div className={styles.authorName}>
                        {trimText(data.name, 30)}
                    </div>
                </div>
            </div>
        </div>
    );
};
