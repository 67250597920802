/* eslint-disable jsx-a11y/anchor-has-content */
import { CarouselCustomDot } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { classNames } from "repoV2/utils/common/render/classNames";
import { SECTION_IDS, DotMatrixIconSvg } from "@Utils";
import React from "react";
import Carousel from "react-multi-carousel";
import commonStyles from "../../root/yoga.module.scss";
import { TestimonialCard } from "./TestimonialCard";
import styles from "./testimonialsSection.module.scss";

const responsive = {
    all: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
    },
};

export const TestimonialsSection = ({
    hideComponent,
    title,
    testimonialsData,
    onTestimonialClick,
    pauseTestimonialsAutoplay,
}: ITemplate.ITestimonialsSectionProps) => {
    if (hideComponent) return null;

    return (
        <div className={styles.root}>
            <a
                id={SECTION_IDS.testimonials}
                className={commonStyles.sectionAnchor}
            />
            <div
                className={classNames(
                    commonStyles.uniformWidth,
                    styles.container
                )}
            >
                <div
                    className={classNames(
                        commonStyles.sectionTitle,
                        styles.title
                    )}
                >
                    {title}
                </div>
                <DotMatrixIconSvg className={styles.dotMatrix} />
                <Carousel
                    responsive={responsive}
                    infinite
                    autoPlay={
                        testimonialsData.length > 1 &&
                        !pauseTestimonialsAutoplay
                    }
                    arrows={false}
                    showDots
                    renderDotsOutside
                    dotListClass={styles.dotListClass}
                    customDot={
                        <CarouselCustomDot
                            design="dot"
                            className={styles.customDot}
                            applyTheme
                        />
                    }
                >
                    {testimonialsData?.map(item => (
                        <TestimonialCard
                            key={item.id}
                            data={item}
                            onTestimonialClick={e =>
                                onTestimonialClick(e, item)
                            }
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
